package nl.icsvertex.web.homescreen.data

import kotlinx.serialization.Serializable

@Serializable
data class Application(
    val name: String,
    val environment: String,
    val icon: String,
    val url: String
)
