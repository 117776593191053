package nl.icsvertex.web.homescreen.ui.components

import mui.icons.material.Image
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML
import web.cssom.*
import web.location.location

external interface ApplicationTileProps : Props {
    var applicationName: String
    var applicationEnvironment: String
    var applicationIcon: String
    var applicationHref: String
}

val ApplicationTile = FC<ApplicationTileProps>("Application_Tile") { props ->
    Card {
        sx {
            minWidth = 245.px
        }
        CardActionArea {
            onClick = {
                location.replace(props.applicationHref)
            }
            CardMedia {
                component = ReactHTML.img
                src = props.applicationIcon
                sx {
                    padding = 8.px
                    height = 140.px
                    width = 100.pct
                    objectFit = ObjectFit.contain
                }
            }
            CardContent {
                sx {
                    display = Display.flex
                    flexFlow = FlexFlow.column
                    justifyContent = JustifyContent.center
                    alignItems = AlignItems.center
                }
                Typography {
                    variant = TypographyVariant.h3
                    +props.applicationName
                }
                Typography {
                    variant = TypographyVariant.subtitle1
                    +props.applicationEnvironment
                }
            }
        }
    }
}